import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Divider, Input, Modal, Table, Tag, Upload, message,
} from 'antd'
import {
  DeleteOutlined, EyeOutlined, LoadingOutlined, PaperClipOutlined, PlusOutlined, UploadOutlined,
} from '@ant-design/icons'

import Api from 'services/api'
import { JWT_TOKEN } from 'app/config'

const Files = ({
  value, onChange, uploadUrl, type, docTypes, customRender, maxCount, empty, postData, allowLinks, ...props
  // type = "id" or "url"
}) => {
  const [files, setFiles] = useState([])
  const [fileName, setFileName] = useState('')
  const [linkUrl, setLinkUrl] = useState('')
  const [uploading, setUploading] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (type === 'id') {
      getInitialFiles()
    } else {
      setFiles(value)
    }
  }, [value])

  const getInitialFiles = async () => {
    const { files: data } = await Api.axios.get('/v4/admin/files', { params: { $filter: { _id: { $in: value }}}})
    setFiles(data)
  }

  const columns = [
    {
      key: 'name',
      render: function renderType({ name }, fileUrl) {
        return (
          <div className='flex items-center space-x-2'>
            <PaperClipOutlined />
            <span>{name || fileUrl}</span>
          </div>
        )
      },
    },
    {
      key: 'action',
      render: (f) => (
        <div className='flex justify-end items-center gap-x-2'>
          <EyeOutlined
            className='text-gray-700'
            onClick={() => window.open(f.fileUrl || f)}
          />
          <DeleteOutlined
            className='text-red-400'
            onClick={() => {
              if (type === 'url') {
                setFiles([...files.filter((file) => file !== f)])
              } else {
                setFiles([...files.filter((file) => file.name !== f.name)])
              }
            }}
          />
        </div>
      ),
    },
  ]

  const renderFiles = () => (
    <Table
      columns={columns}
      dataSource={files}
      pagination={false}
      showHeader={false}
      size="small"
      rowKey="_id"
    />
  )

  const render = customRender || renderFiles

  const uploadParams = {
    name: 'file',
    headers: {
      authorization: localStorage.getItem(JWT_TOKEN),
    },
    data: { fileName, ...postData },
    action: `${process.env.DOMAIN}${uploadUrl}`,
    beforeUpload(file) {
      let isValid = docTypes.some((t) => file.name.includes(t))
      if (!isValid) {
        setError('Format non autorisé. Veuillez sélectionner un autre fichier.')
      } else if (files.length === maxCount) {
        isValid = false
        setError('Limite de documents téléchargés atteinte')
      } else {
        setError('')
      }
      return isValid
    },
    onChange(info) {
      const { status } = info.file
      if (status === 'uploading') {
        setUploading(true)
      }
      if (status === 'done') {
        message.success('Document uploadé avec succès')
        setFileName('')
        let fileValue

        if (type === 'url') {
          fileValue = info.file.response.fileUrl
          setFiles([...files, info.file.response.fileUrl])
          onChange({ fileUrl: fileValue, name: fileName })
        } else {
          fileValue = info.file.response.file._id
          setFiles([...files, info.file.response.file])
          onChange([...value, fileValue])
        }
        setUploading(false)
        setOpen(false)
      } else if (status === 'error') {
        if (info.file.response?.includes('LIMIT_FILE_SIZE')) {
          setError('Attention, fichier trop volumineux. PJ non ajoutée')
        } else {
          setError(`${info.file.name} erreur pendant l'update.`)
        }
        setUploading(false)
      }
    },
  }

  const saveLink = () => {
    setUploading(true)
    onChange({ name: fileName, fileUrl: linkUrl, tags: ['link']})
    setOpen(false)
    setFileName('')
    setLinkUrl('')
    setUploading(false)
  }

  return (
    <>
      <div className='flex items-center space-x-2'>
        <Input
          type="text"
          placeholder="Titre du document"
          value={fileName}
          onChange={(e) => { setFileName(e.target.value) }}
        />
        {!allowLinks
          ? (
            <Upload
              {...props}
              {...uploadParams}
              showUploadList={false}
            >
              <Button
                type="primary"
                disabled={!fileName}
                loading={uploading}
                icon={<PlusOutlined />}
              >
                Uploader
              </Button>
            </Upload>
          )
          : (
            <Button
              type="primary"
              onClick={() => setOpen(true)}
              disabled={!fileName}
              loading={uploading}
              icon={<PlusOutlined />}
            >
              Uploader
            </Button>
          )
        }
      </div>
      {error &&
        <Tag className='w-full text-center text-red-500 my-2' color="red">{error}</Tag>
      }
      {files.length > 0
        ? render()
        : empty
      }
      <Modal
        title='Ajouter un document'
        open={open}
        footer={<></>}
        onCancel={() => setOpen(false)}
      >
        <div className='flex space-x-4 mt-6'>
          <Input
            type="text"
            placeholder="Renseignez une url"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          <Button
            type="primary"
            onClick={saveLink}
            disabled={!linkUrl}
            loading={uploading}
          >
              Enregistrer le lien
          </Button>
        </div>
        <Divider className='text-slate-300'>ou</Divider>
        <Upload.Dragger
          {...props}
          {...uploadParams}
          showUploadList={false}
        >
          <p>
            {uploading
              ? <LoadingOutlined className='text-blue-500 text-3xl' />
              : (
                <UploadOutlined
                  className='text-blue-500 text-3xl' />
              )}
          </p>
          <p className='font-medium'>Cliquez ou faites glisser le fichier dans cette zone pour l'importer</p>
          <p className='font-light text-xs text-slate-500'>Uniquement les fichiers PDF sont autorisés</p>
        </Upload.Dragger>
      </Modal>
    </>
  )
}

Files.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.string,
  uploadUrl: PropTypes.string,
  customRender: PropTypes.func,
  maxCount: PropTypes.number,
  docTypes: PropTypes.array,
  empty: PropTypes.object,
  postData: PropTypes.object,
  allowLinks: PropTypes.bool,
}

Files.defaultProps = {
  value: [],
  type: 'url',
  uploadUrl: '/v4/admin/files/upload',
  maxCount: 20,
  docTypes: ['pdf'],
  empty: <div className='text-zinc-400 m-1 italic'>Aucun fichier disponible</div>,
  postData: {},
  allowLinks: false,
}

export default Files
